import { useTenantsApi } from "api/TenantsAPI";
import Box from "components/Box";
import Container from "components/Container";
import TenantDeck from "components/tenants/TenantDeck";
import useMount from "customhooks/useMount";
import { Tenant } from "dtos/Tenant";
import { theTenant, enableTenantSwitch } from "myrecoil/TenantState";
import { useState } from "react";
import { useRecoilState } from "recoil";
import logo from "public/images/moonlimit-logo-med.png";
import Spinner from "components/Spinner";
import { useAuth } from "customhooks/useAuth";
import { useHistory , useLocation } from "react-router-dom";


const TenantsView: React.FC = () => {
	const [, setSelectedTenant] = useRecoilState(theTenant);
	const [, setEnableTenantSwitch] = useRecoilState(enableTenantSwitch);
	const [allTenants, setAllTenants] = useState<Tenant[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const auth = useAuth();

	const tenantsApi = useTenantsApi();
	const { pathname } = useLocation();
	let history  = useHistory();

	useMount(() => {
		tenantsApi
			.getAllTenants()
			.then((tenants) => {
				if (tenants.length === 1) {
					setEnableTenantSwitch(false);
					setSelectedTenant(tenants[0]);
				} else {
					setEnableTenantSwitch(true);
					setAllTenants(tenants);
				}
				setLoading(false);
			})
			.catch((err) => {
				setError(err.toString());
				setLoading(false);
			});
	});
	return (
		<section className="hero is-fullheight">
			<div className="hero-body dynamic-theme-body ">
				<main className="container is-align-self-flex-start">
					<Container>
						<div
							className="buttons"
							onClick={() => {
								auth.logout();
							}}
						>
							<span
								className="button is-primary"
								style={{
									marginLeft: "auto",
									marginRight: "0px",
								}}
							>
								<strong>Log out</strong>
							</span>
						</div>
						<div
							style={{
								textAlign: "center",
								height: "200px",
								marginBottom: "10px",
							}}
						>
							<img
								alt=""
								style={{ height: "150px" }}
								src={logo}
							></img>
							<h1 className="title">
								Please select a company below.
							</h1>
						</div>
						<Box>
							{loading ? (
								<Spinner mrgTop="65px"></Spinner>
							) : error ? (
								<div
									style={{
										width: "100%",
										textAlign: "center",
									}}
								>
									{error}
								</div>
							) : (
								<TenantDeck
									tenants={allTenants}
									onTenantClicked={(tenant: Tenant) => {
										setSelectedTenant(tenant);
										// logic to redirect to page after tenant switch
										const toks = pathname.split("/");
										if (toks.length > 2)
										{
											if (toks[1] == "mission")
											{
												history.push("/missions");
											}
											else if (toks[1] == "inspection")
											{
												history.push("/inspections");
											}
											else if (toks.length > 3 && toks[1] == "drone" && toks[2] == "status")
											{
												history.push("/drones");
											}
										}
									}}
								></TenantDeck>
							)}
						</Box>
					</Container>
				</main>
			</div>
		</section>
	);
};

export default TenantsView;
