import { useEffect, useState } from "react";
import classes from "./Checkbox.module.scss";

export interface Props {
  checkbox: CheckboxI;
  key?: string | undefined;
  onChange: (item: CheckboxI) => void;
  isRadio?: boolean;
  selectedOption?: string;
  checkAll?: boolean;
}

export interface CheckboxI {
  value: string;
  title: string;
}

function Checkbox({ checkbox, key, onChange, isRadio, selectedOption, checkAll = false }: Props) {
  const [selected, setSelected] = useState<boolean>(checkAll);

  useEffect(() => {
    setSelected(checkAll)
  },[checkAll])

  function onChangeHandler() {
    setSelected(!selected)
    onChange(checkbox)
  }

  return (
    <label key={key} className={classes.checkbox}>
      {isRadio ? (
        <input
          type="radio"
          onChange={() => onChange(checkbox)}
          checked={selectedOption === checkbox.value}
        />
      ) : (
        <input type="checkbox" onChange={onChangeHandler} checked={selected}/>
      )}
      {checkbox.title}
    </label>
  );
}

export default Checkbox;
