import {useApi} from "api/API"
import { useState } from "react";
import { useHistory } from "react-router";

interface Props {
  isActive: boolean;
  closeModal: () => void;
}

const NewDroneModal: React.FC<Props> = ({ isActive, closeModal }) => {
  const [name, setName] = useState<string>("");
  const [tagNumber, setTagNumber] = useState<string>("");
  const [owner, setOwner] = useState<string>("");
  const [platformCode, setPlatformCode] = useState<string>("");
  const [onboardCode, setOnbloardCode] = useState<string>("");
  
  const api = useApi();
  
  let history = useHistory();
  const save = () => {
    api
      .post("drones", {
        name,
        tagNumber,
        owner,
        platformCode,
        onboardCode,
      })
      .then(({ data }) => {
        history.push(`/drone/settings/${data.id}`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className={`modal ${isActive ? " is-active is-clipped" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Create new drone</p>
          <button
            className="delete"
            aria-label="close"
            onClick={closeModal}
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="box">
            <div className="row pt-4">
              <div className="columns">
                <div className="column is-half">
                  <div className="field">
                    <label className="label">Name: </label>
                    <input
                      className="input "
                      type="text"
                      value={name}
                      placeholder="Name"
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    ></input>
                  </div>
                  <div className="field">
                    <label className="label">Tag number: </label>
                    <input
                      className="input "
                      type="text"
                      value={tagNumber}
                      placeholder="tag"
                      onChange={(event) => {
                        setTagNumber(event.target.value);
                      }}
                    ></input>
                  </div>
                  <div className="field">
                    <label className="label">Drone Model: </label>
                    <input
                      className="input "
                      type="text"
                      value={platformCode}
                      placeholder="Drone Model"
                      onChange={(event) => {
                        setPlatformCode(event.target.value);
                      }}
                    ></input>
                  </div>
                </div>
                <div className="column is-half">
                  <div className="field">
                    <label className="label">Owner: </label>
                    <input
                      className="input "
                      type="text"
                      value={owner}
                      placeholder="Owner"
                      onChange={(event) => {
                        setOwner(event.target.value);
                      }}
                    ></input>
                  </div>
                  <div className="field">
                    <label className="label">Computer Model: </label>
                    <input
                      className="input "
                      type="text"
                      value={onboardCode}
                      placeholder="Computer Model"
                      onChange={(event) => {
                        setOnbloardCode(event.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5 is-flex is-justify-content-space-between">
            <button
              onClick={save}
              className="mr-2 button is-primary is-outlined"
            >
              Save
            </button>
            <button
              className="button is-danger is-outlined"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default NewDroneModal;
