import { Assignment } from "dtos/Assignment";
import { AssignmentTable } from "dtos/AssignmentTable";

export module AssignmentStatus {
	export const NOT_STARTED = { value: "NotStarted", label: "Not Started" };
	export const ABORTED = { value: "Aborted", label: "Aborted" };
	export const IN_PROGRESS = { value: "InProgress", label: "In Progress" };
	export const COMPLETED = { value: "Completed", label: "Completed" };
	export const ADD_IMAGES = { value: "AddImages", label: "Add Images" };
	export const REVISED = { value: "Revised", label: "Revised" };
	export const REJECTED = { value: "Rejected", label: "Rejected" };
	export const APPROVED = { value: "Approved", label: "Approved" };
}

export const getAssignmentStatus = (str: string) => {
	let result = getAllAssignmentStatuses().find(
		(value: { value: string; label: string }) => {
			return value.value === str;
		}
	);
	return result ? result : null;
};

const editableAssignmentStatuses = [
	AssignmentStatus.NOT_STARTED,
	AssignmentStatus.ABORTED,
	AssignmentStatus.IN_PROGRESS,
	AssignmentStatus.COMPLETED,
	AssignmentStatus.ADD_IMAGES,
	AssignmentStatus.REVISED,
	AssignmentStatus.REJECTED,
	AssignmentStatus.APPROVED,
];

export const getEditableAssignmentStatuses = () => {
	return editableAssignmentStatuses;
};

export const getAllAssignmentStatuses = () => {
	return [
		AssignmentStatus.NOT_STARTED,
		AssignmentStatus.ABORTED,
		AssignmentStatus.IN_PROGRESS,
		AssignmentStatus.COMPLETED,
		AssignmentStatus.ADD_IMAGES,
		AssignmentStatus.REVISED,
		AssignmentStatus.REJECTED,
		AssignmentStatus.APPROVED,
	];
};

export module TableStatus {
	export const STATUS_NOT_STARTED = 0;
	export const STATUS_ABANDONED = 1;
	export const STATUS_XY_LOCKED = 2;
	export const STATUS_Z_LOCKED = 3;
	export const STATUS_PANELS_COUNTED = 4;
	export const STATUS_PANELS_FOUND = 5;
}

export const getTableStatus = (code: number) => {
	switch (code) {
		case TableStatus.STATUS_ABANDONED:
			return "Abandoned";
		case TableStatus.STATUS_NOT_STARTED:
			return "Pending";
		case TableStatus.STATUS_PANELS_COUNTED:
			return "Panels Counted";
		case TableStatus.STATUS_PANELS_FOUND:
			return "Mapped Successfully";
		case TableStatus.STATUS_XY_LOCKED:
			return "Horizontal Position Found";
		case TableStatus.STATUS_Z_LOCKED:
			return "Vertical Position Found";
		default:
			return "Unknown";
	}
};

export const assingmentsDateComparator = (a: Assignment, b: Assignment) => {
	if (a.timeCompleted && b.timeCompleted) {
		let timeA = new Date(a.timeCompleted).getTime();
		let timeB = new Date(b.timeCompleted).getTime();
		if (timeA > timeB) {
			return -1;
		} else if (timeA === timeB) {
			return 0;
		} else {
			return 1;
		}
	} else if (a.timeCompleted) {
		return -1;
	} else if (b.timeCompleted) {
		return 1;
	} else {
		return 0;
	}
};

export const getInspectionStatusLabel = (status: string) => {
	switch (status) {
		case "Problem":
			return "Damaged";
		case "PANEL_BROKEN_OTHER":
			return "Other problem";
		case "PANEL_BROKEN_CELL":
			return "Hot cell";
		case "PANEL_BROKEN_MULTICELL":
			return "Multiple hot cells";
		case "PANEL_BROKEN_DIODE":
			return "Bypass Diode";
		case "PANEL_BROKEN_MODULE":
			return "Hot module";
		case "PANEL_BROKEN_MULTIMODULE":
			return "Multiple hot modules";
		case "PANEL_BROKEN_VEGETATION":
			return "Vegetation problem";
		case "PANEL_BROKEN_SUNREFLECTION":
			return "Sun reflection";
		case "PANEL_BROKEN_DIRT":
			return "Soiling";
		case "PANEL_BROKEN_CRACKING":
			return "Module cracking";
		case "PANEL_BROKEN_MISSING":
			return "Missing module";
		case "NotInspected":
			return "Not Inspected";
		default:
			return "Ok";
	}
};

export const getInspectionStatusNLabel = (tableInfo: AssignmentTable) => {
	let brokenPanels = 0;
	tableInfo.panels.forEach((panel) => {
		if (panel.inspectionStatus !== "Ok" && panel.inspectionStatus !== "NotInspected") {
			brokenPanels++;
		}
	});
	return brokenPanels;
};
export type Losses = {
	Count: number,
	EffLoss: number,
	PowerLoss: number,
	EnergyLoss: number,
	RevenueLoss: number,
	panelPrice: number,
	inCurrency:string,
	Name: string,
};

export type LossesByProblem = {
	problem: string,
	losses: Losses,
};

export type SortedLossesByProblem = {
	problems: LossesByProblem[],
	total: Losses
};

export const getByInspectionStatus = (assignment: Assignment) => {
	let brokenPanels: { [key: string]: Losses } ={ };

	let price = assignment.siteInfo?.pricePerKWH === undefined ? 0 : assignment.siteInfo?.pricePerKWH;
	let currency = assignment.siteInfo?.priceSymbolSuffix;

	brokenPanels['NotInspected'] = { Count: 0, EffLoss: 0, EnergyLoss: 0, PowerLoss: 0, RevenueLoss: 0, panelPrice: price, inCurrency:  currency, Name: "Not Inspected" };
	brokenPanels['Ok'] = { Count: 0, EffLoss: 0, EnergyLoss: 0, PowerLoss: 0, RevenueLoss: 0, panelPrice: price, inCurrency: currency, Name: "Ok" };
	brokenPanels['PANEL_BROKEN_CRACKING'] = { Count: 0, EffLoss: 100.0, EnergyLoss: 0, PowerLoss: 0, RevenueLoss: 0, panelPrice: price, inCurrency: currency, Name: "Module Cracking" };
	brokenPanels['PANEL_BROKEN_DIRT'] = { Count: 0, EffLoss: 5.0, EnergyLoss: 0, PowerLoss: 0, RevenueLoss: 0, panelPrice: price, inCurrency: currency, Name: "Soiling" };
	brokenPanels['PANEL_BROKEN_SUNREFLECTION'] = { Count: 0, EffLoss: 0, EnergyLoss: 0, PowerLoss: 0, RevenueLoss: 0, panelPrice: price, inCurrency: currency, Name: "Sun Reflection" };
	brokenPanels['PANEL_BROKEN_VEGETATION'] = { Count: 0, EffLoss: 33.0, EnergyLoss: 0, PowerLoss: 0, RevenueLoss: 0, panelPrice: price, inCurrency: currency, Name: "Vegetation / Shadowing" };
	brokenPanels['PANEL_BROKEN_MULTIMODULE'] = { Count: 0, EffLoss: 100.0, EnergyLoss: 0, PowerLoss: 0, RevenueLoss: 0, panelPrice: price, inCurrency: currency, Name: "Multiple Hot Modules" };
	brokenPanels['PANEL_BROKEN_MODULE'] = { Count: 0, EffLoss: 100.0, EnergyLoss: 0, PowerLoss: 0, RevenueLoss: 0, panelPrice: price, inCurrency: currency, Name: "Hot Module" };
	brokenPanels['PANEL_BROKEN_DIODE'] = { Count: 0, EffLoss: 33.0, EnergyLoss: 0, PowerLoss: 0, RevenueLoss: 0, panelPrice: price, inCurrency: currency, Name: "Bypass Diode" };
	brokenPanels['PANEL_BROKEN_MULTICELL'] = { Count: 0, EffLoss: 50.0, EnergyLoss: 0, PowerLoss: 0, RevenueLoss: 0, panelPrice: price, inCurrency: currency, Name: "Multiple Hot Cells" };
	brokenPanels['PANEL_BROKEN_CELL'] = { Count: 0, EffLoss: 33.0, EnergyLoss: 0, PowerLoss: 0, RevenueLoss: 0, panelPrice: price, inCurrency: currency, Name: "Hot Cell" };
	brokenPanels['PANEL_BROKEN_OTHER'] = { Count: 0, EffLoss: 0, EnergyLoss: 0, PowerLoss: 0, RevenueLoss: 0, panelPrice: price, inCurrency: currency, Name: "Other Problem" };
	brokenPanels['PANEL_BROKEN_MISSING'] = { Count: 0, EffLoss: 100, EnergyLoss: 0, PowerLoss: 0, RevenueLoss: 0, panelPrice: price, inCurrency: currency, Name: "Missing Module" };
	brokenPanels['Problem'] = { Count: 0, EffLoss: 0, EnergyLoss: 0, PowerLoss: 0, RevenueLoss: 0, panelPrice: price, inCurrency: currency, Name: "Other Problem" };
	brokenPanels['TOTAL'] = { Count: 0, EffLoss: 0, EnergyLoss: 0, PowerLoss: 0, RevenueLoss: 0, panelPrice: price, inCurrency: currency, Name: "Total" };
	let operating_hours_in_year = 365 * 4.1;
	let power_lost_watts = 0; // total powerlost
	let annual_energy_loss = operating_hours_in_year * power_lost_watts;
	assignment.areas.forEach((area) => {
		area.rows.forEach((row) => {
			row.tables.forEach((table) => {
				table.panels.forEach((panel) => {
					power_lost_watts += (brokenPanels[panel.inspectionStatus].EffLoss * panel.ratedPowerW) / 100.0;
				});
			});
		});
	});
	assignment.areas.forEach((area) => {
		area.rows.forEach((row) => {
			row.tables.forEach((table) => {
				table.panels.forEach((panel) => {
					brokenPanels[panel.inspectionStatus].Count = brokenPanels[panel.inspectionStatus].Count + 1;
					brokenPanels[panel.inspectionStatus].PowerLoss += (brokenPanels[panel.inspectionStatus].EffLoss * panel.ratedPowerW) / 100000.0;
					brokenPanels[panel.inspectionStatus].RevenueLoss += operating_hours_in_year * (brokenPanels[panel.inspectionStatus].EffLoss * panel.ratedPowerW) / 100000.0 * price;
					brokenPanels[panel.inspectionStatus].EnergyLoss += operating_hours_in_year * ((brokenPanels[panel.inspectionStatus].EffLoss * panel.ratedPowerW) / 100000.0);

					if (panel.inspectionStatus.startsWith('PANEL_'))
					{
						brokenPanels['TOTAL'].Count += 1;
						brokenPanels['TOTAL'].PowerLoss += (brokenPanels[panel.inspectionStatus].EffLoss * panel.ratedPowerW) / 100000.0;
						brokenPanels['TOTAL'].RevenueLoss += operating_hours_in_year * (brokenPanels[panel.inspectionStatus].EffLoss * panel.ratedPowerW) / 100000.0 * price;
						brokenPanels['TOTAL'].EnergyLoss += operating_hours_in_year * ((brokenPanels[panel.inspectionStatus].EffLoss * panel.ratedPowerW) / 100000.0);
					}
				});
			});
		});
	});

	var items = Object.keys(brokenPanels).map(function(key) {
		return {problem: key, losses: brokenPanels[key]};
	  });

	items = items.filter(i => { return (i.losses.Count > 0 && i.problem != 'TOTAL' && i.problem != 'Ok' && i.problem != 'Problem'); });
	items.sort((first, second) => {
		let powerDiff = second.losses.PowerLoss - first.losses.PowerLoss;
		if (powerDiff !== 0.0) {
			return powerDiff;
		} else {
			let countDiff = second.losses.Count - first.losses.Count;
			if (countDiff !== 0) {
				return countDiff;
			} else {
				return second.losses.EffLoss - first.losses.EffLoss;
			}
		}
	});
	return {
		problems: items,
		total: brokenPanels['TOTAL']
	} as SortedLossesByProblem;
};
