export const useReportDownload = () => {
	return {
		download: (link: string) => {
			let href = link;
			let element = document.createElement("a");
			element.setAttribute("href", href);
			element.setAttribute("download", "");

			element.style.display = "none";
			document.body.appendChild(element);

			element.click();

			document.body.removeChild(element);
		},
	};
};
