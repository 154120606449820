/* eslint-disable jsx-a11y/anchor-is-valid */
import { useApi } from "api/API";
import Container from "components/Container";
import {
	DroneCommands,
	DroneStatusMap,
	DroneStatusOffline,
	DroneStatusReport,
	Telemetry
} from "components/drone";
import Spinner from "components/Spinner";
import useInterval from "customhooks/useInterval";
import useMount from "customhooks/useMount";
import { Drone, Mission, Point, StatusReport } from "dtos";
import { isOnline } from "helpers/DroneUtils";
import { useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useHistory, useParams } from "react-router";

const DroneStatusView: React.FC = () => {
	const { id } = useParams<{ id: string | undefined }>();
	let history = useHistory();

	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);

	const [drone, setDrone] = useState<Drone | null>(null);
	const [mission, setMission] = useState<Mission | null>(null);
	const [plannedRoute, setPlannedRoute] = useState<Point[]>();
	const [statusReport, setStatusReport] = useState<StatusReport>();

	const api = useApi();

	useMount(() => {
		if (id && id !== "undefined") {
			api.get(`drones/${id}`)
				.then(({ data }) => {
					if (data) {
						setDrone(data);
						setLoading(false);
						if (data.assignedMissionId) {
							api.get(`missions/${data.assignedMissionId}`).then(
								({ data }) => {
									if (data) {
										setMission(data);
									}
								}
							).catch(reason => {});
						}
						api.get(`drones/${id}/plannedreport`).then(
							({ data }) => {
								if (data && data.waypointsJson) {
									setPlannedRoute(
										JSON.parse(data.waypointsJson)
									);
								}
							}
						).catch(reason => {});
						if (isOnline(data)) {
							api.get(`drones/${id}/statusreport/latest`).then(
								({ data }) => {
									if (data) {
										setStatusReport(data);
									}
								}
							).catch(reason => {});
						}
					} else {
						setError("Select an existing drone.");
						setLoading(false);
					}
				})
				.catch((e) => {
					setError(e.toString());
					setLoading(false);
				});
		} else {
			setError("Select a drone.");
			setLoading(false);
		}
	});

	useInterval(
		() => {
			if (id && id !== "undefined") {
				api.get(`drones/${id}`).then(({ data }) => {
					if (data) {
						setDrone(data);
						if (!isOnline(data)) {
							setStatusReport(undefined);
						}
					}
				}).catch(reason => {});
			}
		},
		drone && isOnline(drone) ? 10000 : 30000
	);

	useInterval(
		() => {
			api.get(`drones/${id}/statusreport/latest`).then(({ data }) => {
				if (data) {
					setStatusReport(data);
				}
			}).catch(reason => {});
		},
		drone && isOnline(drone) ? 3000 : null
	);

	return (
		<>
			<Container>
				<div style={{ textAlign: "start" }}>
					<button
						onClick={() => {
							history.goBack();
						}}
						className="button is-info is-inverted"
					>
						<FaAngleLeft></FaAngleLeft>Back
					</button>
				</div>
			</Container>
			{loading ? (
				<Spinner></Spinner>
			) : error ? (
				<div style={{width: "100%", textAlign: "center"}}>{error}</div>
			) : (
				<>
					<h2 className="subtitle is-2">Drone real-time status</h2>
					{statusReport && (
						<DroneCommands
							droneId={drone!.id}
							statusReport={statusReport}
						></DroneCommands>
					)}
					{!statusReport && (
						<DroneStatusOffline
							droneName={drone?.name}
							missionName={drone?.assignedMission?.name}
						></DroneStatusOffline>
					)}
					{statusReport && (
						<DroneStatusReport
							statusReport={statusReport}
						></DroneStatusReport>
					)}
					{statusReport && statusReport.position && (
						<Telemetry
							reportPosition={statusReport.position}
						></Telemetry>
					)}
					<DroneStatusMap
						mission={mission ? mission : undefined}
						plannedRoute={plannedRoute}
						droneLocation={
							statusReport && statusReport.position
								? statusReport.position.location
								: undefined
						}
					></DroneStatusMap>
				</>
			)}
		</>
	);
};

export default DroneStatusView;
