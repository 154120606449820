import { Mission, Area } from "dtos";

export const getAreaColor = (kind: number | string) => {
  switch (kind) {
    case 1:
    case "Avoid":
      return "#F08080"; //avoid
    case 2:
    case "Interest":
      return "#7CFC00"; //interest
    case 3:
    case "Mission":
      return "#FFD700"; //mission
    default:
      return "white"; //unknown
  }
};

export const generateAreaID = (area: Area) => {
  let id = area.id ? area.id : "area";
  area.points.forEach((point) => {
    id += `${point.lat}${point.lng}`;
  });
  return id;
};

export module AreaTypes {
  export const AVOID = {num: 1, str: "Avoid"}
  export const INTEREST = {num: 2, str: "Interest"};
  export const MISSION = {num: 3, str: "Mission"};
}

export const getMinMaxLatLng = (mission: Mission, other: {lat: number, lng: number}[] | null, includeBases: boolean, includeMissionArea: boolean) => {
  let minLat = 100000.0;
  let maxLat = -100000.0;
  let minLng = 100000.0;
  let maxLng = -100000.0;
  let valid = false;
  if (other)
  {
    other.forEach((point) => {
      minLat = Math.min(point.lat, minLat);
      maxLat = Math.max(point.lat, maxLat);
      minLng = Math.min(point.lng, minLng);
      maxLng = Math.max(point.lng, maxLng);
      valid = true;
    });
  }
  mission.areas.forEach((area) => {
    if (!includeMissionArea && (area.kind == AreaTypes.MISSION.num || area.kind == AreaTypes.MISSION.str))
    {
      return;
    }
    if (!includeMissionArea && (area.kind == AreaTypes.AVOID.num || area.kind == AreaTypes.AVOID.str))
    {
      return;
    }
    area.points.forEach((point) => {
      minLat = Math.min(point.lat, minLat);
      maxLat = Math.max(point.lat, maxLat);
      minLng = Math.min(point.lng, minLng);
      maxLng = Math.max(point.lng, maxLng);
      valid = true;
    });
  });
  if (includeBases)
  {
    mission.droneBases.forEach((point) => {
      minLat = Math.min(point.lat, minLat);
      maxLat = Math.max(point.lat, maxLat);
      minLng = Math.min(point.lng, minLng);
      maxLng = Math.max(point.lng, maxLng);
      valid = true;
    });
  }
  return {
    min: {lat: minLat, lng: minLng},
    max: {lat: maxLat, lng: maxLng},
    valid: valid
  }
};

export const ProjectToPixels = (input: {lat: number, lng: number}, center: {lat: number, lng: number}, zoomlevel: number) =>
{
  // half of the earth circumference's in pixels at zoom level 21
  const offset = 268435456;
  const radius = offset / Math.PI;
  const LngToX = (lng: number) => {
    return Math.round(offset + radius * lng * Math.PI / 180.0);
  };
  const LatToY = (lat: number) => {
    return (Math.round(offset - radius * Math.log((1.0 +
      Math.sin(lat * Math.PI / 180.0)) / (1.0 - Math.sin(lat *
      Math.PI / 180.0))) / 2.0));
  };
  const xr = (LngToX(input.lng) - LngToX(center.lng)) >> (21 - zoomlevel);
  const yr = (LatToY(input.lat) - LatToY(center.lat)) >> (21 - zoomlevel);
  return {x: xr,
          y: yr};
};

export const getMapZoomLevel = (
  minMaxLatLng: {min: {lat: number, lng: number}, max: {lat: number, lng: number}, valid: boolean}, 
  tgtWidth: number, 
  tgtHeight: number) => {
  if (minMaxLatLng.valid)
  {
    const midLatLng = {lat: 0.5 * (minMaxLatLng.max.lat + minMaxLatLng.min.lat),
                       lng: 0.5 * (minMaxLatLng.max.lng + minMaxLatLng.min.lng)};
    for (var zoom = 21; zoom >= 1; --zoom)
    {
      var maxPt = ProjectToPixels(minMaxLatLng.max, midLatLng, zoom);
      if (2 * Math.abs(maxPt.x) < tgtWidth && 2 * Math.abs(maxPt.y) < tgtHeight)
      {
        return zoom;
      }
    }
  }
  return 18;
};

export const getMapCenterAndZoomLevel = (mission: Mission, 
                                         includeBases: boolean, 
                                         includeMissionArea: boolean, 
                                         tgtWidth: number, 
                                         tgtHeight: number) => {
  const minMaxLatLng = getMinMaxLatLng(mission, null, includeBases, includeMissionArea);
  if (minMaxLatLng.valid)
  {
    const midLatLng = {lat: 0.5 * (minMaxLatLng.max.lat + minMaxLatLng.min.lat),
                       lng: 0.5 * (minMaxLatLng.max.lng + minMaxLatLng.min.lng)};
    for (var zoom = 21; zoom >= 1; --zoom)
    {
        var maxPt = ProjectToPixels(minMaxLatLng.max, midLatLng, zoom);
        if (2 * Math.abs(maxPt.x) < tgtWidth && 2 * Math.abs(maxPt.y) < tgtHeight)
        {
          return {
            center: midLatLng,
            zoom: zoom
          };
        }
    }
    return {
      center: midLatLng,
      zoom: 18
    };
  }
  return {
    center: {lat: 42.59844067953348, lng: 23.04053410395984},
    zoom: 18
  };
};
