import { LoadScript, GoogleMap, LoadScriptProps } from "@react-google-maps/api";
import { MutableRefObject, useRef } from "react";
import mapCenterIcon from "public/images/map-center.svg";
import {getMapZoomLevel} from "helpers/MissionAreaUtils";

declare var google: any;

interface Props {
	mapCenter: { lat: number; lng: number };
	setMapCenter: (point: { lat: number; lng: number }) => void;
	mapZoomLevel: number;
	setMapZoomLevel: (zoom: number) => void;
	onClick?: (event: any) => void;
	onLoad?: (map: google.maps.Map) => void;
	addUserLocation?: boolean;
	mapTilt: number;
	mapMinMax?: {min: {lat: number; lng: number}, max: {lat: number; lng: number}, valid: boolean};
}
const libraries: LoadScriptProps['libraries'] = ["drawing"];
//const libraries: Array<"drawing" | "places" | "geometry" | "localContext" | "places" |"visualization"> = ['drawing'];

const MapView: React.FC<Props> = ({
	children,
	mapCenter,
	setMapCenter,
	mapZoomLevel,
	setMapZoomLevel,
	onClick,
	onLoad,
	addUserLocation,
	mapTilt,
	mapMinMax,
}) => {
	const mapRef: MutableRefObject<any> = useRef(null);

	const onMapLoad = (map: google.maps.Map) => {
		if (mapMinMax && mapMinMax.valid) {
			// re-center and re-zoom the map
			map.setCenter({lat: 0.5 * (mapMinMax.max.lat + mapMinMax.min.lat), lng: 0.5 * (mapMinMax.max.lng + mapMinMax.min.lng)});
			map.setZoom(getMapZoomLevel(mapMinMax, map.getDiv().clientWidth, map.getDiv().clientHeight));
		}
		if (addUserLocation) {
			mapRef.current = map;

			//add location button
			const infoWindow = new google.maps.InfoWindow();
			const locationButton = document.createElement("button");
			locationButton.classList.add("map-center-button");
			locationButton.title = "Center map to your current location.";
			const mapCenterImg = document.createElement("img");
			mapCenterImg.src = mapCenterIcon;
			locationButton.appendChild(mapCenterImg);

			map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
				locationButton
			);
			locationButton.addEventListener("click", () => {
				// Try HTML5 geolocation.
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(
						(position: GeolocationPosition) => {
							const pos = {
								lat: position.coords.latitude,
								lng: position.coords.longitude,
							};
							infoWindow.setPosition(pos);
							infoWindow.setContent("Your current location.");
							infoWindow.open(map);
							map.setCenter(pos);
						},
						() => {
							handleLocationError(
								true,
								infoWindow,
								map.getCenter()!
							);
						}
					);
				} else {
					// Browser doesn't support Geolocation
					handleLocationError(false, infoWindow, map.getCenter()!);
				}
			});
		}
		function handleLocationError(
			browserHasGeolocation: boolean,
			infoWindow: any,
			pos: any
		) {
			infoWindow.setPosition(pos);
			infoWindow.setContent(
				browserHasGeolocation
					? "Error: The Geolocation service failed."
					: "Error: Your browser doesn't support geolocation."
			);
			infoWindow.open(map);
		}
		if (onLoad)
		{
			onLoad(map);
		}
	};

	const onCenterChanged = () => {
		if (
			mapRef.current &&
			mapCenter.lat !== mapRef.current.center.lat() &&
			mapCenter.lat !== mapRef.current.center.lng()
		) {
			let newCenter = {
				lat: mapRef.current.center.lat(),
				lng: mapRef.current.center.lng(),
			};
			setMapCenter(newCenter);
		}
	};

	const onZoomChanged = () => {
		if (mapRef.current && mapZoomLevel !== mapRef.current.zoom) {
			setMapZoomLevel(mapRef.current.zoom);
		}
	};

	// disable the street view control
	const mapOptions = {streetViewControl: false} as google.maps.MapOptions;

	return (
		<LoadScript
			id="script-loader"
			googleMapsApiKey="AIzaSyBIb_o3iZLT7EBD00W3ZvStycZ7W9zrPHk"
			language="en"
			region="us"
			libraries={libraries}
		>
			<GoogleMap
				mapContainerClassName="google_map_container"
				center={mapCenter}
				zoom={mapZoomLevel}
				tilt={mapTilt}
				mapTypeId="satellite"
				onLoad={onMapLoad}
				onCenterChanged={onCenterChanged}
				onZoomChanged={onZoomChanged}
				onClick={onClick}
				options={mapOptions}
			>
				{children}
			</GoogleMap>
		</LoadScript>
	);
};

export default MapView;
