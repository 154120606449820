import Modal from "components/ui/Modal/Modal";
import Spinner from "components/Spinner";
import { useApi } from "api/API";
import { useState } from "react";
import {
  typeOfDmg,
} from "views/AssignmentView/utils/constants";
import { FaDownload } from "react-icons/fa";

interface Props {
  assignmentId: string;
}

interface ReportStatus {
  progress: number;
  state: string;
  reportId: string;
  assignmentId: string;
  reportFormat: string;
  problemTypes: string;
  hasImages: boolean;
  queueNumber: number;
  timestampGeneratedUtc: string;
  downloadLink: string;
}

interface ReportStatuses {
  reports: ReportStatus[];
}

interface ReportStatusesResponse {
  data: ReportStatuses;
  status: number;
  statusText: string;
}

function DownloadReportModal({ assignmentId }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState([] as ReportStatus[]);

  const api = useApi();
  const handleOpenDownloadReport = () => {
    api.get(`reports/status`)
    .then((pr: ReportStatusesResponse) => {
      if (pr.data.reports != null)
      {
        setReports(pr.data.reports.filter(m => m.assignmentId == assignmentId && m.state != "Failed"));
      }
      setIsLoading(false);
    }).catch(reason => {
      console.log("Error getting available reports: ", reason);
      setIsLoading(false);
    });
    setIsLoading(true);
    setIsOpen(true);
  }

  const convertToReadableProblemType = (problemType: string) => {
    const pt = typeOfDmg.filter(t => t.value == problemType);
    if (pt.length > 0) {
      return pt[0].title;
    } else {
      return "Unknown Problem (" + problemType + ")";
    }
  }

  const printProblemTypes = (report: ReportStatus) => {
    if (report.problemTypes.length == 0) {
      return "All";
    }
    else {
      // check if all problem types are listed
      const enabledTypes = report.problemTypes.split(",");
      const disabledTypes = typeOfDmg.filter(t => !enabledTypes.includes(t.value));
      if (disabledTypes.length == 0)
      {
        return "All";
      }
      // list the selected problem types
      return enabledTypes.map(problemType => convertToReadableProblemType(problemType)).join(", ");
    }
  }

  const acceptButton = (
    <span></span>
  );

  return (
    <>
      <button
        onClick={handleOpenDownloadReport}
        className="button is-success is-light is-outlined"
      >
        <FaDownload style={{ marginRight: "5px" }} />
        Download Reports
      </button>

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Download a Report"
        acceptButton={acceptButton}
      >
        <h2><b>Reports</b></h2>
        {isLoading && (
          <Spinner mrgTop="65px"></Spinner>
        )}
        {!isLoading && reports.length > 0 && (
          <table>
            <tr>
              <td style={{borderBottom: "2px solid black", paddingRight: "20px"}}>Generated On</td>
              <td style={{borderBottom: "2px solid black", paddingRight: "20px"}}>Report Settings</td>
              <td style={{borderBottom: "2px solid black"}}>Link</td>
            </tr>
          {reports.map((report) => (
            <tr>
              <td style={{borderBottom: "1px solid black", paddingRight: "20px"}}>
                { new Date(report.timestampGeneratedUtc).toLocaleString("en-GB") }
              </td>
              <td style={{borderBottom: "1px solid black", paddingRight: "20px"}}>
                Problem Types: { printProblemTypes(report) }<br/>
                Images: {report.hasImages ? "Yes" : "No"}
              </td>
              <td style={{borderBottom: "1px solid black"}}>
                { report.state == "Generated" && (
                  <a href={report.downloadLink}>Download {report.reportFormat}</a>
                )}
                { report.state == "Queued" && (
                  <span>Report is number {report.queueNumber} in the queue</span>
                )}
                { report.state == "Generating" && (
                  <span>Report is being generated ({report.progress}%)</span>
                )}
              </td>
            </tr>
          ))}
          </table>
        )}
        {!isLoading && reports.length == 0 && (
          <span>No reports available</span>
        )}
      </Modal>
    </>
  );
}

export default DownloadReportModal;
