import CheckboxList from "components/ui/CheckboxList/CheckboxList";
import Modal from "components/ui/Modal/Modal";
import RadioButtons from "components/ui/RadioButtons/RadioButtons";
import { useRef, useState } from "react";
import {
  additionalSettings,
  fileFormats,
  typeOfDmg,
} from "views/AssignmentView/utils/constants";
import { FaFilePdf } from "react-icons/fa";

interface Props {
  setQueryParams: React.Dispatch<React.SetStateAction<string>>;
  setFileFormat: React.Dispatch<React.SetStateAction<string>>;
  onGenerateReport: () => void;
}

function GenerateReportModal({ setQueryParams, setFileFormat, onGenerateReport }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  let isGenerateImgs = useRef<boolean>(false);
  let typeOfDmgParam = useRef<string>("");
  let reportFormat = useRef<string>("");

  const selectDmgHandler = (chosenDmg: string[]) => {
    const transformParams = chosenDmg.join(",");
    typeOfDmgParam.current = transformParams;
    queryParamsHandler();
  };

  const selectFormatHandler = (chosenFormat: string) => {
    reportFormat.current = chosenFormat;
    setFileFormat(reportFormat.current);
  };

  const selectAdditionalHandler = (chosenOption: string[]) => {
    isGenerateImgs.current = chosenOption.includes("NoImages");
    queryParamsHandler();
  };

  const queryParamsHandler = () => {
    const newParams = `&lightVersion=${isGenerateImgs.current}${
      typeOfDmgParam.current ? `&problemTypes=${typeOfDmgParam.current}` : ""
    }`;
    setQueryParams(newParams);
  };

  const generateReportHandler = () => {
    setIsOpen(false)
    onGenerateReport()
  }

  const handleOpenGenerateReport = () => {
    setIsOpen(true);
  }

  const acceptButton = (
    <button onClick={generateReportHandler} className='button is-success is-light is-outlined'>
      Generate a New Report
    </button>
  );

  return (
    <>
      <button
        onClick={handleOpenGenerateReport}
        className="button is-success is-light is-outlined"
      >
        <FaFilePdf style={{ marginRight: "5px" }} />
        Generate a Report
      </button>

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Generate a New Report"
        acceptButton={acceptButton}
      >
        <RadioButtons
          onSelect={selectFormatHandler}
          forKey="format_"
          options={fileFormats}
          title="File Format"
          initialSelectedOption="PDF"
        ></RadioButtons>
        <hr />
        <CheckboxList
          onSelect={selectDmgHandler}
          forKey="dmg_"
          options={typeOfDmg}
          title="Damage type"
          selectAllOption={true}
        ></CheckboxList>
        <hr />
        <CheckboxList
          onSelect={selectAdditionalHandler}
          forKey="additional_"
          options={additionalSettings}
          title="Additional Settings"
        ></CheckboxList>
      </Modal>
    </>
  );
}

export default GenerateReportModal;
