import { Polygon } from "@react-google-maps/api";
import { AssignmentTable } from "dtos/AssignmentTable";
import { useRecoilState } from "recoil";
import { selectedTable as selectedTableAtom, selectedProblemTypes as problemTypesAtom } from "myrecoil/AssignmentState";
import { useEffect } from "react";

interface Props {
	table: AssignmentTable;
	area: number;
	row: number;
	col: number;
}
const Table: React.FC<Props> = ({ table, row, col, area }) => {
	const [selectedTable, setSelectedTable] = useRecoilState(selectedTableAtom);
	const [problemTypes, setProblemTypes] = useRecoilState(problemTypesAtom);
	let isSelected = selectedTable?.table === table;
	useEffect(() => () => setSelectedTable(null), []);
	const getStatusColor = (table: AssignmentTable) => {
		if (table.inspectionStatus === "Ok")
		{
			return "green";
		}
		if (table.inspectionStatus === "NotInspected")
		{
			return "orange";
		}
		const unclassified = table.panels.filter(p => p.inspectionStatus == "PANEL_BROKEN_OTHER");
		if (unclassified.length > 0)
		{
			return "orangered";
		}
		const unfiltered = table.panels.filter(p => !problemTypes.exclude.includes(p.inspectionStatus) && p.inspectionStatus !== "Ok");
		if (unfiltered.length > 0)
		{
			// contains some problem types that are not excluded
			return "crimson";
		}
		// all problem types are excluded
		return "green";
	};
	return (
		<Polygon
			onClick={() => {
				setSelectedTable({ table, area, row, col });
			}}
			path={table.gps_Polygon}
			options={{
				strokeColor: isSelected
					? "white"
					: getStatusColor(table),
				fillColor: isSelected ? "white" : undefined,
			}}
		/>
	);
};
export default Table;
