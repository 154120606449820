import { useTenantsApi } from "api/TenantsAPI";
import Box from "components/Box";
import useMount from "customhooks/useMount";
import { Tenant, User } from "dtos";
import { theTenant } from "myrecoil/TenantState";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useRecoilValue } from "recoil";

interface Props {
	isActive: boolean;
	addUser: (user: User) => void;
	closeModal: () => void;
	currentUsers: User[];
}

const AddUserModal: React.FC<Props> = ({
	isActive,
	closeModal,
	addUser,
	currentUsers,
}) => {
	const [fromTenant, setFromTenant] = useState<Tenant | null>(null);
	const [tenants, setTenants] = useState<Tenant[]>([]);
	const currentTenant = useRecoilValue(theTenant);
	const tenantsApi = useTenantsApi();

	const [users, setUsers] = useState<User[]>([]);
	const [selectedUser, setSelectedUser] = useState<User | null>();

	useMount(() => {
		tenantsApi
			.getAllTenants()
			.then((tens) =>
				setTenants(
					tens.filter((tenant) => tenant.id !== currentTenant?.id)
				)
			);
	});

	useEffect(() => {
		setSelectedUser(null);
		setUsers([]);
		if (fromTenant) {
			tenantsApi.getTenantUsers(fromTenant.id).then(({ data }) => {
				setUsers(
					data.filter((u: User) => {
						return !currentUsers.find((cu) => {
							return cu.id === u.id;
						});
					})
				);
			});
		}
	}, [fromTenant]);

	return (
		<div className={`modal ${isActive ? " is-active is-clipped" : ""}`}>
			<div className="modal-background"></div>
			<div className="modal-card">
				<header className="modal-card-head">
					<p className="modal-card-title">Select a user</p>
					<button
						className="delete"
						aria-label="close"
						onClick={closeModal}
					></button>
				</header>
				<section className="modal-card-body">
					<Box>
						<div className="row pt-4">
							<span
								style={{
									fontSize: "1.25rem",
									marginRight: "5px",
								}}
							>
								<b>From: </b>
							</span>
							<div
								style={{
									display: "inline-block",
									width: "15em",
								}}
							>
								<Select
									placeholder={"Select a Company"}
									getOptionLabel={(tenant: Tenant) =>
										tenant.name
									}
									getOptionValue={(tenant: Tenant) =>
										tenant.id
									}
									name="typeSelect"
									value={fromTenant}
									onChange={(tenant: Tenant | null) => {
										setFromTenant(tenant);
									}}
									options={tenants}
								></Select>
							</div>
						</div>
						<div className="row pt-4">
							<span
								style={{
									fontSize: "1.25rem",
									marginRight: "5px",
								}}
							>
								<b>User: </b>
							</span>
							<div
								style={{
									display: "inline-block",
									width: "15em",
								}}
							>
								<Select
									placeholder={"Select an User"}
									getOptionLabel={(user: User) =>
										user.userName
									}
									getOptionValue={(user: User) => user.id}
									name="typeSelect"
									value={selectedUser}
									onChange={(user: User | null) => {
										setSelectedUser(user);
									}}
									options={users}
								></Select>
							</div>
						</div>
						<div className="row mt-5 is-flex is-justify-content-space-between">
							<button
								className="mr-2 button is-primary is-outlined"
								onClick={() => {
									addUser(selectedUser!);
									closeModal();
								}}
								disabled={!selectedUser}
							>
								Add
							</button>
						</div>
					</Box>
				</section>
			</div>
		</div>
	);
};

export default AddUserModal;
