import { Assignment } from "dtos";
import { useState } from "react";
import GenerateReportModal from "../../Modals/GenerateReportModal/GenerateReportModal";
import DownloadReportModal from "../../Modals/DownloadReportModal/DownloadReportModal";
import classes from "./ReportActions.module.css";
import useInterval from "customhooks/useInterval";
import { useApi } from "api/API";
import { useReportDownload } from "customhooks/useReportDownload";
import ProgressBar from "components/ProgressBar";
interface Props {
  assignment: Assignment;
}

interface ReportStatus {
  progress: number;
  state: string;
  reportId: string;
  assignmentId: string;
  reportFormat: string;
  problemTypes: string;
  hasImages: boolean;
  queueNumber: number;
  timestampGeneratedUtc: string;
  downloadLink: string;
}

interface ReportStatuses {
  reports: ReportStatus[];
}

interface ReportStatusResponse {
  data: ReportStatus;
  status: number;
  statusText: string;
}

interface ReportStatusesResponse {
  data: ReportStatuses;
  status: number;
  statusText: string;
}

function ReportActions({ assignment }: Props) {
  const [fileFormat, setFileFormat] = useState<string>("PDF");
  const [queryParams, setQueryParams] = useState<string>("");
  const [progress, setProgress] = useState<ReportStatus | null>(null);
  const [isActive, setIsActive] = useState<boolean>(false);

  const api = useApi();
  const download = useReportDownload();
  useInterval(() => {
      api
        .get(`reports/status`)
        .then((pr: ReportStatusesResponse) => {
          if (pr.data.reports != null)
          {
            for (var ii = 0; ii < pr.data.reports.length; ++ii)
            {
              if (progress?.reportId === pr.data.reports[ii].reportId)
              {
                if ("Failed" === pr.data.reports[ii].state) {
                  console.log("Report generation stopped unexpectedly!");
                  setProgress(null);
                } else {
                  if ("Generated" === pr.data.reports[ii].state) {
                    download.download(pr.data.reports[ii].downloadLink);
                    setProgress(null);
                  } else {
                    setProgress(pr.data.reports[ii]);
                  }
                }
              }
            }
          }
        }).catch(reason => {
          console.log("Error getting export status: ", reason);
        });
    },
    progress != null && progress.state != "Failed" && progress.state != "Generated" ? 5000 : null
  );

  const generateReport = () => {
    const generateUrl = `reports/generate?to=${fileFormat}&assignmentId=${assignment.id}${queryParams}`;
    api.get(generateUrl).then((pr: ReportStatusResponse) => {
      if ("Failed" === pr.data.state) {
        console.log("Failed to initiate report generation!");
        setProgress(null);
      } else {
        if ("Generated" === pr.data.state) {
          download.download(pr.data.downloadLink);
          setProgress(null);
        } else {
          setProgress(pr.data);
        }
      }
    }).catch(reason => console.log("Failed to initiate report generation!", reason));
    setIsActive(false);
  };

  return (
    <div className={classes.download_report_bar}>
      <DownloadReportModal
        assignmentId={assignment.id}
      ></DownloadReportModal>
      {progress != null && progress.state == "Queued" && (
        <div style={{ width: "auto" }}>Report is number { Math.max(1, progress.queueNumber) } in the queue</div>
      )}
      {progress != null && progress.state != "Failed" && progress.state != "Generated" && progress.state != "Queued" && (
        <div style={{ width: 100 }}><ProgressBar completed={progress.progress} /></div>
      )}
      {(progress == null || progress.state == "Failed" || progress.state == "Generated") && (
        <GenerateReportModal
          setQueryParams={setQueryParams}
          setFileFormat={setFileFormat}
          onGenerateReport={generateReport}
        ></GenerateReportModal>
      )}
    </div>
  );
}

export default ReportActions;
