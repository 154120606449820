import { UserModel } from "api/API";
import { useTenantsApi } from "api/TenantsAPI";
import { useUserApi } from "api/UsersAPI";
import Spinner from "components/Spinner";
import UserRow from "components/UserRow";
import AddUserModal from "components/users/AddUserModal";
import MoveUserModal from "components/users/MoveUserModal";
import UserModal from "components/users/UserModal";
import useMount from "customhooks/useMount";
import { useUserMessaging } from "customhooks/useUserMessaging";
import { User } from "dtos/User";
import { theTenant } from "myrecoil/TenantState";
import { userAtom } from "myrecoil/UserState";
import { useState } from "react";
import { FaUser } from "react-icons/fa";
import { useRecoilState } from "recoil";

const UsersView: React.FC = () => {
	const [users, setUsers] = useState<User[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);
	const [currentTenant] = useRecoilState(theTenant);
	const [selectedUser, setSelectedUser] = useState<User | null>(null);
	const [movedUser, setMovedUser] = useState<User | null>(null);
	const [actualUser] = useRecoilState<UserModel | null>(userAtom);
	const [showAddUser, setShowAddUser] = useState<boolean>(false);
	const userApi = useUserApi(currentTenant!.id);
	const tenantsApi = useTenantsApi();

	const myGetAllUsers = () => {
		setLoading(true);
		userApi
			.getAllUsers()
			.then((allUsers) => {
				setUsers(allUsers);
				setLoading(false);
				setError(null);
			})
			.catch((error) => {
				setLoading(false);
				if (404 === error.response?.status) {
					setError("No users.");
				} else {
					setError(error.toString());
				}
			});
	};

	useMount(() => {
		myGetAllUsers();
	});

	const userMessaging = useUserMessaging();

	return (
		<div className="container ">
			<UserModal
				user={selectedUser}
				saveUser={(user: User) => {
					user = {
						...user,
						companyAccountId: parseInt(currentTenant!.id),
					};
					if (!user.id) {
						user.password = "Password_123";
					}
					return userApi.saveUser(user).then(() => {
						myGetAllUsers();
						setSelectedUser(null);
					});
				}}
				closeModal={() => {
					setSelectedUser(null);
				}}
				deleteUser={(user: User) => {
					userApi.deleteUser(user).then(() => myGetAllUsers());
				}}
			></UserModal>
			<MoveUserModal
				closeModal={() => {
					setMovedUser(null);
				}}
				user={movedUser}
				moveUser={(user: User, to: string) => {
					tenantsApi.joinUser(user, to).then(() => {
						tenantsApi.unlinkUser(user).then(() => myGetAllUsers());
					});
				}}
			></MoveUserModal>
			<AddUserModal
				isActive={showAddUser}
				closeModal={() => {
					setShowAddUser(false);
				}}
				addUser={(user: User) => {
					tenantsApi.joinUser(user, null).then(() => myGetAllUsers());
				}}
				currentUsers={users}
			></AddUserModal>
			<div className="buttons">
				<span
					className="button is-primary is-light is-outlined"
					onClick={() => {
						setSelectedUser({} as User);
					}}
				>
					<span>Create New User</span>
					<FaUser size={20} className="ml-2"></FaUser>
				</span>
				<span
					className="button is-success is-light is-outlined"
					onClick={() => {
						setShowAddUser(true);
					}}
				>
					<span>Add User</span>
					<FaUser size={20} className="ml-2"></FaUser>
				</span>
			</div>
			<div className="Rtable header Rtable--6cols">
				<div className="Rtable-cell">User Name</div>
				<div className="Rtable-cell">Role</div>
				<div className="Rtable-cell only-wide-screen">Email</div>
				<div className="Rtable-cell only-wide-screen">First Name</div>
				<div className="Rtable-cell only-wide-screen">Last Name</div>
				<div className="Rtable-cell">Options</div>
			</div>
			{loading ? (
				<Spinner mrgTop="65px"></Spinner>
			) : error ? (
				<div style={{ width: "100%", textAlign: "center" }}>
					{error}
				</div>
			) : (
				users.map((user: User) => {
					return (
						<UserRow
							key={user.id}
							user={user}
							activeUser={actualUser?.sub}
							viewUser={(user: User) => setSelectedUser(user)}
							moveUser={(user: User) => {
								setMovedUser(user);
							}}
							unlinkUser={(user: User) => {
								tenantsApi
									.unlinkUser(user)
									.then(() => myGetAllUsers())
									.catch((e) => {
										userMessaging.showError(
											"Can't unlink user. Either move it to another company or delete it."
										);
									});
							}}
						></UserRow>
					);
				})
			)}
		</div>
	);
};

export default UsersView;
